// src/components/NavigationMenu.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../assests/styles/NavigationMenu.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faEnvelope, faPenToSquare, faNewspaper, faBookQuran, faGhost, faPlay } from '@fortawesome/free-solid-svg-icons';

const NavigationMenu = () => {
    return (
        <nav className="nav-menu">
            <div className="menu-item">
                <Link to="/blogs">
                    <FontAwesomeIcon icon={faNewspaper} />
                    <p>Blogs</p>
                </Link>
            </div>
            <div className="menu-item">
                <Link to="/stories">
                    <FontAwesomeIcon icon={faBookQuran} />
                    <p>Stories</p>
                </Link>
            </div>
            <div className="menu-item">
                <Link to="/videos">
                    <FontAwesomeIcon icon={faPlay} />
                    <p>Videos</p>
                </Link>
            </div>
            <div className="menu-item">
                <Link to="/writers">
                    <FontAwesomeIcon icon={faPenToSquare} />
                    <p>Writers</p>
                </Link>
            </div>
            <div className="menu-item">
                <Link to="/creators">
                    <FontAwesomeIcon icon={faGhost} />
                    <p>Creators</p>
                </Link>
            </div>
            <div className="menu-item">
                <Link to="/about">
                    <FontAwesomeIcon icon={faUsers} />
                    <p>About Us</p>
                </Link>
            </div>
            <div className="menu-item">
                <Link to="/contact">
                    <FontAwesomeIcon icon={faEnvelope} />
                    <p>Contact Us</p>
                </Link>
            </div>
        </nav>
    );
};

export default NavigationMenu;
