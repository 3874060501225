import React from 'react';
import '../assests/styles/Videos.scss';
import HomeIcon from './HomeIcon';

// Sample Videos data (list of YouTube short video IDs)
const videos = [
    {
        id: 'G4vaG7i3rh4' // Replace with actual YouTube video IDs
    },
    {
        id: 'uWnBxiMcoZE' // Replace with actual YouTube video IDs  OtxHF95ZvCw
    },
    {
        id: 'uzZP7sWjbfk'
    },
    {
        id: 'j1iwCEJCpVs'
    },
    {
        id: 'NwADZFeBvdA'
    },  
];

const Videos = () => {
    return (
        <div>
            <HomeIcon />
            <div className="Videos-Page">
                <div className='Videos-Title'>
                    <h1 className="Videos-Title0">AmongIslam</h1>
                    <h1 className="Videos-Title1">Videos</h1>
                </div>
                <div className="Videos-Container">
                    {videos.map(video => (
                        <div className="video-item" key={video.id}>
                            <iframe
                                width="100%"
                                // height="315"
                                src={`https://www.youtube.com/embed/${video.id}`}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Videos;
